import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockDefault.module.css';
import { NamedLink } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ensureUser } from '../../../../util/data';
import { BOTH, BUYER, SELLER } from '../../../../util/constants';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    isAuthenticated,
    currentUser
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  const ensuredUser = ensureUser(currentUser);
  const fetchUserTypeIfAny = isAuthenticated ? ensuredUser?.attributes?.profile?.publicData?.userType : BOTH;

  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={title} options={options} />
          <Field data={text} options={options} />
          <Field data={callToAction} className={ctaButtonClass} options={options} />
          {blockId == "hero-column" && <ul>
            <li>
              <NamedLink name="AlgoliaSearchPage">
                <FormattedMessage id='BlockDefault.shopNowText' />
              </NamedLink>
              {!isAuthenticated ?
                <NamedLink name="SignupPage">
                  <FormattedMessage id='BlockDefault.sellNowText' />
                </NamedLink>
                : null}
            </li>
          </ul>}
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

const mapStateToProps = state =>{
  const { isAuthenticated } = state.auth;
  const { currentUser } = state.user;
  return{
    isAuthenticated,
    currentUser
  }
};

const mapDispatchToProps = dispatch =>({});

export default compose(connect(mapStateToProps, mapDispatchToProps))(BlockDefault);
